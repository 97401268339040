<template>
  <div class="error-content error-403">
    <div>
      <h2>{{ $t("header403") }}</h2>
      <h3>{{ $t("header403Second") }}</h3>
    </div>
    <img src="/img/error403.png" alt="Error 403" class="error-background bg-403" />
    <div class="text-403">
      <h5>{{ $t("content403") }}</h5>
      <button class="btn btn-md btn-primary-light error-button-403" @click.prevent="goToLandingPage">{{ $t("goBack") }}</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'error403',
  i18nOptions: { namespaces: 'errors' },
  methods: {
    goToLandingPage() {
      this.$router.push('/');
    },
  },

};
</script>
