<template>
  <div class="error-content error-500">
    <Error500 class="bg-svg" />
    <div class="panel-500">
      <div>
        <h2>{{ $t("header500") }}</h2>
        <h3>{{ $t("header500Second") }}</h3>
      </div>
      <img src="/img/error.png" alt="Error 500" class="inner-img" />
      <div>
        <h5>{{ $t("content500") }}</h5>
        <button class="btn btn-md btn-primary-light error-button" @click.prevent="goToLandingPage">{{ $t("goBack") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Error500 from '../components/icons/Error500';

export default {
  name: 'error500',
  i18nOptions: { namespaces: 'errors' },
  components: {
    Error500,
  },
  computed: {
    ...mapState({
      profile: state => state.profile.profile,
    }),
  },
  methods: {
    goToLandingPage() {
      this.$router.push(this.profile ? this.profile.role.name === 'neo-worker' ? '/companies-searches' : '/search' : '/');
    },
  },
};
</script>
